<template>
    <div>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="3">
                        <strong>Version</strong>
                    </b-col>
                    <b-col cols="9">
                        <template v-if="editMode">
                            <b-form-group :state="errorState('version')"
                                          invalid-feedback="Required">
                                <b-input id="note-version"
                                         v-model="note.workspace.releaseVersion"
                                         placeholder="Release version"
                                         :state="errorState('version')"
                                         size="sm" />
                            </b-form-group>
                        </template>
                        <template v-else>{{note.releaseVersion}}</template>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="3">
                        <strong>Details</strong>
                    </b-col>
                    <b-col cols="9">
                        <template v-if="editMode">
                            <b-form-group :state="errorState('note')"
                                          invalid-feedback="Required">
                                <RichTextEditor id="note-note"
                                                v-model="note.workspace.note"
                                                :state="errorState('note')"
                                                size="sm" />
                            </b-form-group>
                        </template>
                        <template v-else><span v-html="note.note" /></template>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="3">
                        <strong>Publish Date</strong>
                    </b-col>
                    <b-col cols="9">
                        <template v-if="editMode">
                            <b-form-group :state="errorState('publishDate')"
                                          invalid-feedback="Required">
                                <PickerOfDates type="datetime"
                                               format="M/d/yyyy h:mm a"
                                               id="note-published"
                                               v-model="note.workspace.publishDate"
                                               :state="errorState('publishDate')"
                                               :time-picker-options="timePickerOptions"
                                               size="sm" />
                            </b-form-group>
                        </template>
                        <template v-else>{{note.publishDate | formatDate}}</template>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div v-if="!note.isNew"
                                  style="text-align: right">
                            Created by {{note.createdByUsername}} {{note.createdDate | formatDate}}
                        </div>
                        <div v-if="note.hasUpdate"
                                  style="text-align: right">
                            Updated by  {{note.updatedByUsername}} {{note.updatedDate | formatDate}}
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <br />
                        <b-button-group class="float-right">
                            <template v-if="!editMode && canEdit">
                                <b-button variant="dark"
                                          @click="edit">Edit</b-button>
                            </template>
                            <template v-else-if="editMode">
                                <b-button variant="warning"
                                          @click="cancel">Cancel</b-button>
                                <b-button v-if="canDelete"
                                          variant="danger"
                                          @click="deleteSave">Delete</b-button>
                                <b-button variant="success"
                                          @click="save">Save</b-button>
                            </template>
                        </b-button-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>


<script>
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import RichTextEditor from '@/components/shared/RichTextEditor.vue';
    import PickerOfDates from '@/components/shared/PickerOfDates';
    import {NULL_RELEASE_NOTE, ReleaseNote} from "@/model/notification";
    import {date, errorToastOptions} from '@/util/formatters';

    @Component({
        filters: {
            formatDate: (d) => date(d, 'M/d/yyyy h:mm a'),
        },
        components: {
            RichTextEditor,
            PickerOfDates
        }
    })

    export default class ReleaseNoteDetail extends Vue {
        @Prop({type: ReleaseNote, default: function() { return NULL_RELEASE_NOTE; }}) note;

        editMode = false;

        get timePickerOptions() {
            return {
                start: '00:00',
                step: '00:15',
                end: '23:45',
                format: 'h:mm a'
            };
        }

        errorState(field) {
            return !this.note.workspace.errors[field];
        }

        edit() {
            // console.log(this.note);
            this.editMode = true;
        }

        done() {
            this.$emit('done');
            this.editMode = false;
        }

        cancel() {
            this.note.rollback();
            this.done();
        }

        async deleteSave() {
            const check = await this.$bvModal.msgBoxConfirm(
                'Are you sure you wish to delete this release note?', {
                    title: 'Confirm Delete',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
            if (!check) {
                return;
            }
            this.note.workspace.deleted = true;
            await this.save();
        }

        async save() {
            try {
                await this.$store.dispatch('notification/processReleaseNote', this.note.workspace);
                if (this.note.isNew) {
                    this.note.rollback();
                    this.updateEditMode();
                }
                else {
                    this.note.commit();
                    this.editMode = false;
                }
                this.$emit('save');
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }

        get canEdit() {
            //Notes are editable by authorized IT support users, regardless of original author
            const loggedInUser = this.$store.getters['userSession/getUser'];
            const supportUsers = this.$store.getters['notification/supportUsers'];  //parent calls loader
            return supportUsers.includes(loggedInUser.username);
        }

        get canDelete() {
            //Can only delete a non-new note
            return !this.note.isNew;
        }

        updateEditMode() {
            //Only default to edit mode for new notes
            this.editMode = this.note.isNew && this.canEdit;
        }

        mounted() {
            // console.log(this.note);
            this.updateEditMode();
        }
    }
</script>


<style scoped>
</style>
<!--<style>-->
<!--    /*Known bug between CKEditor5 and Bootstrap modals - modal blocks RTE functions*/-->
<!--    /*Below solution displays link dialogue but selection cannot be made*/-->
<!--    /*Note that listening for editor events to toggle no-enforce-focus on modal doesn't work either*/-->
<!--    body {-->
<!--        &#45;&#45;ck-z-default: 100;-->
<!--        &#45;&#45;ck-z-modal: calc( var(&#45;&#45;ck-z-default) + 999 );-->
<!--    }-->
<!--</style>-->