<template>
    <div>
        <b-row>
            <b-col>
                <b-tabs v-model="tabIndex"
                        content-class="mt-3">
                    <b-tab title="User Notifications">
                        <template v-if="hasUserNotifications">
                            <b-card-group deck>
                                <b-card no-body>
                                    <b-list-group flush>
                                        <b-list-group-item v-for="notification in userNotifications"
                                                           :key="notification.id">
                                            <b-row>
                                                <b-col>
                                                    <b-link v-b-toggle="'notification-'+notification.id"
                                                            class="float-left">{{notification.subject}}</b-link>
                                                </b-col>
<!--                                                <b-col>-->
<!--                                                    <b-link v-b-toggle="'notification-'+notification.id"-->
<!--                                                            class="float-left">{{notification.publishDate | formatDate}}</b-link>-->
<!--                                                </b-col>-->
                                            </b-row>
                                            <b-collapse :id="'notification-'+notification.id"
                                                        accordion="userNotifications">
                                                <br />
                                                <UserNotificationDetail :notification="notification"
                                                                        @done="closeNotification(notification)"
                                                                        @save="closeNotification(notification)" />
                                            </b-collapse>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </b-card-group>
                        </template>
                        <template v-else>
                            No user notifications available
                        </template>
                    </b-tab>
                    <b-tab lazy
                           title="Create New User Notification"
                           v-if="canCreateNotification"
                           title-link-class="bg-dark text-light">
                        <!--NEW USER NOTIFICATION-->
                        <!--Not using modal due to known bug with CKEditor and Bootstrap-->
                        <UserNotificationDetail :note="newNotification"
                                                @done="closeNewNotification"
                                                @save="closeNewNotification" />
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator';
    import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
    import {NULL_USER_NOTIFICATION} from "@/model/notification";
    import store from '@/store/store';
    import _ from 'underscore';
    import UserNotificationDetail from "./UserNotificationDetail";
    import {errorToastOptions} from '@/util/formatters';
    import {date} from '@/util/formatters';
    import {sprintf} from "sprintf-js";

    @Component({
        filters: {
            formatDate: (d) => date(d, 'M/d/yyyy h:mm a'),
        },
        components: {
            UserNotificationDetail
        }
    })

    export default class UserNotifications extends Vue {
        tabIndex = 0;
        newNotification = NULL_USER_NOTIFICATION.clone();

        async beforeRouteEnter(to, from, next) {
            store.commit('addBreadcrumb', Breadcrumb.create('User Notifications', null, true));
            next();
        }

        closeNewNotification() {
            this.tabIndex = 0;
        }

        closeNotification(notification) {
            this.$root.$emit('bv::toggle::collapse', sprintf('notification-%s', notification.id));
        }

        get isLoggedInUserAnAdmin() {
            const loggedInUser = this.$store.getters['userSession/getUser'];
            return loggedInUser.isAnAdministrator();
        }

        get canCreateNotification() {
            //Notifications are created by admin users, regardless of original author
            return this.isLoggedInUserAnAdmin;
        }

        get hasUserNotifications() {
            return !_.isEmpty(this.userNotifications);
        }

        get userNotifications() {
            const notifications = this.$store.getters['notification/userNotifications'];
            return notifications;
        }

        async mounted() {
            try {
                await this.$store.dispatch('notification/loadUserNotifications');
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }
    }
</script>

<style scoped>

</style>