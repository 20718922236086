<template>
    <div>
        <b-row>
            <b-col>
                <b-tabs v-model="tabIndex"
                        content-class="mt-3">
                    <b-tab title="Current Release Note">
                        <template v-if="hasCurrentRelease">
                            <ReleaseNoteDetail :note="currentReleaseNote" />
                        </template>
                        <template v-else>
                            No current release notes available
                        </template>
                    </b-tab>
                    <b-tab title="Release Notes">
                        <template v-if="hasReleaseNotes">
                            <b-card-group deck>
                                <b-card no-body>
                                    <b-list-group flush>
                                        <b-list-group-item v-for="note in releaseNotes"
                                                           :key="note.id">
                                            <b-row>
                                                <b-col>
                                                    <b-link v-b-toggle="'note-'+note.id"
                                                            class="float-left">Version {{note.releaseVersion}}</b-link>
                                                </b-col>
                                                <b-col>
                                                    <b-link v-b-toggle="'note-'+note.id"
                                                            class="float-right">Published {{note.publishDate | formatDate}}</b-link>
                                                </b-col>
                                            </b-row>
                                            <b-collapse :id="'note-'+note.id"
                                                        accordion="pastReleaseNotes">
                                                <br />
                                                <ReleaseNoteDetail :note="note"
                                                                   @done="closeNote(note)"
                                                                   @save="closeNote(note)" />
                                            </b-collapse>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </b-card-group>
                        </template>
                        <template v-else>
                            No release notes available
                        </template>
                    </b-tab>
                    <b-tab lazy
                           title="Create New Release Note"
                           v-if="canCreateNote"
                           title-link-class="bg-dark text-light">
                        <!--NEW RELEASE NOTE-->
                        <!--Not using modal due to known bug with CKEditor and Bootstrap-->
                        <ReleaseNoteDetail :note="newNote"
                                           @done="closeNewNote"
                                           @save="closeNewNote" />
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator';
    import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
    import {NULL_RELEASE_NOTE} from "@/model/notification";
    import store from '@/store/store';
    import _ from 'underscore';
    import ReleaseNoteDetail from "./ReleaseNoteDetail";
    import {errorToastOptions} from '@/util/formatters';
    import {date} from '@/util/formatters';
    import {sprintf} from "sprintf-js";
    import {isAfter} from 'date-fns';

    @Component({
        filters: {
            formatDate: (d) => date(d, 'M/d/yyyy h:mm a'),
        },
        components: {
            ReleaseNoteDetail
        }
    })

    export default class ReleaseNotes extends Vue {
        tabIndex = 0;
        newNote = NULL_RELEASE_NOTE.clone();

        async beforeRouteEnter(to, from, next) {
            store.commit('addBreadcrumb', Breadcrumb.create('Release Notes', null, true));
            next();
        }

        closeNewNote() {
            this.tabIndex = 0;
        }

        closeNote(note) {
            this.$root.$emit('bv::toggle::collapse', sprintf('note-%d', note.id));
        }

        get canCreateNote() {
            //Notes are created by authorized IT support users, regardless of original author
            const loggedInUser = this.$store.getters['userSession/getUser'];
            const supportUsers = this.$store.getters['notification/supportUsers'];  //parent calls loader
            return supportUsers.includes(loggedInUser.username);
        }

        get releaseNotes() {
            const notes = this.$store.getters['notification/releaseNotes'];
            return notes;
        }

        get hasCurrentRelease() {
            return !_.isEqual(this.currentReleaseNote, NULL_RELEASE_NOTE);
        }

        get hasReleaseNotes() {
            return !_.isEmpty(this.releaseNotes);
        }

        get currentReleaseNote() {
            //Find returns the first value in the list that meets the condition
            //We know that notes are sorted by descending publish date so index 0 should be the most recent
            //However, for admin users, "future" notes could be included and shouldn't be considered current
            const now = new Date();
            // console.log(this.releaseNotes);
            const currentNote = _.find(this.releaseNotes, n => {
                return isAfter(now, n.publishDate);
            });
            return currentNote || NULL_RELEASE_NOTE;
        }

        async mounted() {
            try {
                await this.$store.dispatch('notification/loadSupportUsers');
                await this.$store.dispatch('notification/loadReleaseNotes');
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }
    }
</script>

<style scoped>
</style>